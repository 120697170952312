<template>
	<div>
		<div>
			<h5 class="marginR10 marginB10">数据总览</h5>
			<div class="flexA marginB10 font14 color666">
				<div class="marginR20" :class="[currentDate==1?'color112':'']" @click="search(1)">最近7天</div>
				<div class="marginR20" :class="[currentDate==2?'color112':'']" @click="search(2)">最近14天</div>
				<div class="marginR20" :class="[currentDate==3?'color112':'']" @click="search(3)">最近30天</div>
				<div class="flexA marginR20">
					<el-date-picker class="marginR10" v-model="timeInterval" @change="search(4)" type="daterange" :disabled-date="disabledDate" value-format="YYYY-MM-DD" start-placeholder="开始日期"
						end-placeholder="结束日期" :clearable="false" />
				</div>
				<!-- <div :class="[currentDate==5?'color112':'']" @click="getCurrentDate(5)">累计</div> -->
			</div>
			<div class="flexA marginB20 font14 color333" style="width: 100%;">
				<el-card class="marginR4">
					<div class="marginB10 color666">阅读（次）</div>
					<div class="flexS">
						<div class="fontB font20 marginR20">{{dataInfo.readVolume||0}}</div>
						<div>
							<div class="flexA marginB10">
								<div class="marginR10 color666">今日</div>
								{{dataInfoToday.readVolume||0}}
							</div>
							<div class="flexA">
								<div class="marginR10 color666">环比</div>
								<div class="marginR10">{{dataInfo.readVolumeSurround||0}}</div>
								<el-icon v-if="dataInfo.readVolumeSurround<0" color="red"><Bottom /></el-icon>
								<el-icon v-if="dataInfo.readVolumeSurround>0" color='green'><Top /></el-icon>
							</div>
						</div>
					</div>
				</el-card>
				<el-card class="marginR4">
					<div class="marginB10 color666">分享（次）</div>
					<div class="flexS">
						<div class="fontB font20 marginR20">{{dataInfo.shareVolume||0}}</div>
						<div>
							<div class="flexA marginB10">
								<div class="marginR10 color666">今日</div>
								{{dataInfoToday.shareVolume||0}}
							</div>
							<div class="flexA">
								<div class="marginR10 color666">环比</div>
								<div class="marginR10">{{dataInfo.shareVolumeSurround||0}}</div>
								<el-icon v-if="dataInfo.shareVolumeSurround<0" color="red"><Bottom /></el-icon>
								<el-icon v-if="dataInfo.shareVolumeSurround>0" color='green'><Top /></el-icon>
							</div>
						</div>
					</div>
				</el-card>
				<el-card class="marginR4">
					<div class="marginB10 color666">转发（次）</div>
					<div class="flexS">
						<div class="fontB font20 marginR20">{{dataInfo.forwardVolume||0}}</div>
						<div>
							<div class="flexA marginB10">
								<div class="marginR10 color666">今日</div>
								{{dataInfoToday.forwardVolume||0}}
							</div>
							<div class="flexA">
								<div class="marginR10 color666">环比</div>
								<div class="marginR10">{{dataInfo.forwardVolumeSurround||0}}</div>
								<el-icon v-if="dataInfo.forwardVolumeSurround<0" color="red"><Bottom /></el-icon>
								<el-icon v-if="dataInfo.forwardVolumeSurround>0" color='green'><Top /></el-icon>
							</div>
						</div>
					</div>
				</el-card>
				<el-card class="marginR4">
					<div class="marginB10 color666">评论（次）</div>
					<div class="flexS">
						<div class="fontB font20 marginR20">{{(dataInfo.commentVolume - dataInfo.deleteCommentVolume)||0}}</div>
						<div>
							<div class="flexA marginB10">
								<div class="marginR10 color666">今日</div>
								<div class="marginR10 flexA">
									<div class="marginR10">增加</div>
									{{dataInfoToday.commentVolume||0}}
								</div>
								<div class="flexA">
									<div class="marginR10">减少</div>
									{{dataInfoToday.deleteCommentVolume||0}}
								</div>
							</div>
							<div class="flexA">
								<div class="marginR10 color666">环比</div>
								<div class="marginR10">{{dataInfo.commentVolumeTotalSurround||0}}</div>
								<el-icon v-if="dataInfo.commentVolumeTotalSurround<0" color="red"><Bottom /></el-icon>
								<el-icon v-if="dataInfo.commentVolumeTotalSurround>0" color='green'><Top /></el-icon>
							</div>
						</div>
					</div>
				</el-card>
				<el-card class="marginR4">
					<div class="marginB10 color666">点赞（次）</div>
					<div class="flexS">
						<div class="fontB font20 marginR20">{{(dataInfo.likeVolume - dataInfo.unlikeVolume)||0}}</div>
						<div>
							<div class="flexA marginB10">
								<div class="marginR10 color666">今日</div>
								<div class="marginR10 flexA">
									<div class="marginR10">增加</div>
									{{dataInfoToday.likeVolume||0}}
								</div>
								<div class="flexA">
									<div class="marginR10">减少</div>
									{{dataInfoToday.unlikeVolume||0}}
								</div>
							</div>
							<div class="flexA">
								<div class="marginR10 color666">环比</div>
								<div class="marginR10">{{dataInfo.likeVolumeTotalSurround||0}}</div>
								<el-icon v-if="dataInfo.likeVolumeTotalSurround<0" color="red"><Bottom /></el-icon>
								<el-icon v-if="dataInfo.likeVolumeTotalSurround>0" color='green'><Top /></el-icon>
							</div>
						</div>
					</div>
				</el-card>
				<el-card>
					<div class="marginB10 color666">收藏（次）</div>
					<div class="flexS">
						<div class="fontB font20 marginR20">{{(dataInfo.collectionVolume - dataInfo.cancelCollectionVolume)||0}}</div>
						<div>
							<div class="flexA marginB10">
								<div class="marginR10 color666">今日</div>
								<div class="marginR10 flexA">
									<div class="marginR10">增加</div>
									{{dataInfoToday.collectionVolume||0}}
								</div>
								<div class="flexA">
									<div class="marginR10">减少</div>
									{{dataInfoToday.cancelCollectionVolume||0}}
								</div>
							</div>
							<div class="flexA">
								<div class="marginR10 color666">环比</div>
								<div class="marginR10">{{dataInfo.collectionVolumeTotalSurround||0}}</div>
								<el-icon v-if="dataInfo.collectionVolumeTotalSurround<0" color="red"><Bottom /></el-icon>
								<el-icon v-if="dataInfo.collectionVolumeTotalSurround>0" color='green'><Top /></el-icon>
							</div>
						</div>
					</div>
				</el-card>
			</div>
			<!-- <div class="flexA marginB24">
				<el-radio-group class="marginR60" v-model="type" label="size control" fill='#112DF2' size="default">
					<el-radio-button label="1" @change="changeType">趋势图</el-radio-button>
				    <el-radio-button label="2" @change="changeType">数据列表</el-radio-button>
				</el-radio-group>
			</div> -->
			<h5>趋势图</h5>
			<div ref="echartsContainer" id="echartsContainer" style='height: 300px;width: 100%'></div>
			<div>
				<h5 class="marginR10 marginB10">内容排名</h5>
				<el-table :data="tableData" :empty-text="emptyText" style="width: 100%;margin-top: 10px;">
					<el-table-column prop="ranking" label="排名" />
					<el-table-column prop="title" label="内容标题" min-width="200" />
					<el-table-column prop="publishedTime" label="发布日期" min-width="160" />
					<el-table-column prop="readVolume" label="阅读次数" />
					<el-table-column prop="readVolumeRate" label="阅读次数占比" />
					<el-table-column prop="createdTime" label="操作">
						<template #default="{row}">
							<el-button link type="primary" plain color="#112DF2" @click="previewItem(row.id)">详情</el-button>
						</template>
					</el-table-column>
				</el-table>
				<div class="paginations">
					<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="prev, pager, next" :total="total">
					</el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {articleDailyStatisticsDaily,articleDailyStatisticsPageListDaily,articleDailyStatisticsToday,articleRankingByCondition} from '@/api'
	import * as echarts from 'echarts'
	// require(['echarts/theme/macarons'])
	import {formatDateOrder,dateFormat,getRecentDays} from '@/utils/data'
	import { Search } from "@element-plus/icons-vue";
	export default {
		data () {
			return {
				Search,
				type:'1',
				currentDate:1,
				dataInfo:{},
				dataInfoToday:{},
				timeInterval:[],
				tableData:[],
				pageSize:10,
				pageNum: 1,
				total:0,
				emptyText:'暂无数据'
			}
		},
		computed:{
			// 计算出本月第一天和最后一天
			countDate () {
			  let end = new Date()
			  let start = new Date()
			  start = new Date(start.getFullYear(), start.getMonth(), 1)
			  return [
			    dateFormat(start, 'YYYY-MM-DD'),
			    dateFormat(end, 'YYYY-MM-DD')
			  ]
			}
		},
		created() {
			this.timeInterval = this.countDate
			this.search(this.currentDate)
			this.getDataToday()
			// this.getData()
		},
		methods:{
			getData(){
				let body = {
					"paging": {
						"pageNum": this.pageNum,
						"pageSize": this.pageSize
					}
				}
				let num = this.currentDate
				let timeInterval = JSON.parse(JSON.stringify(this.timeInterval))
				if(num!=4){
					this.timeInterval = []
				}
				body.beginDate = num==1?getRecentDays(7)[0]:num==2?getRecentDays(14)[0]:num==3?getRecentDays(30)[0]:timeInterval[0]
				body.endDate = num!=4?this.countDate[1]:timeInterval[1]
				articleRankingByCondition(body).then(res=>{
					if(res&&res.success){
						let list = res.data.list
						list.forEach(item=>{
							item.publishedTime = formatDateOrder(item.publishedTime)
							item.readVolumeRate = item.readVolumeRate?(item.readVolumeRate+'%'):item.readVolumeRate
						})
						this.tableData = list
						this.total = Number(res.data.total)
					}
				})
			},
			previewItem(id){
				let num = this.currentDate
				let type
				if(num==4){
					type = num + '/' + JSON.parse(JSON.stringify(this.timeInterval))
				}else{
					type = num
				}
				this.$router.push('/knowledgeClassroom/contentAnalysis/'+id+'/'+type)
			},
			changeType(){
				if(this.type == '1'){
					this.search(this.currentDate)
					return
				}
				this.pageNum = 1
				this.pageSize = 10
				this.total = 0
				this.tableData = []
				this.getList()
			},
			getList(){
				let body = {
					articleId:this.$route.params.id,
					"paging": {
						"pageNum": this.pageNum,
						"pageSize": this.pageSize
					}
				}
				let num = this.currentDate
				let timeInterval = JSON.parse(JSON.stringify(this.timeInterval))
				if(num!=4){
					this.timeInterval = []
				}
				body.beginDate = num==1?getRecentDays(7)[0]:num==2?getRecentDays(14)[0]:num==3?getRecentDays(30)[0]:timeInterval[0]
				body.endDate = num!=4?this.countDate[1]:timeInterval[1]
				articleDailyStatisticsPageListDaily(body).then(res=>{
					if(res&&res.success){
						this.exPorts = {
							beginDate:body.beginDate,
							endDate:body.endDate
						}
						let list = res.data.list
						this.tableData = list
						this.total = Number(res.data.total)
					}
				})
			},
			getDataToday(){
				articleDailyStatisticsToday({}).then(res=>{
					if(res&&res.success){
						this.dataInfoToday = res.data
					}
				})
			},
			init(){
				var myChart = echarts.init(this.$refs.echartsContainer);
				// // 绘制图表
				let dataType = ['阅读量','点赞量','分享量','转发量','评论量','收藏量']
				let series = [
					{
						name:'阅读量',
						type:'line',
						data:this.readVolumeList
					},
					{
						name: '点赞量',
						type: 'line',
						data:this.likeVolumeList
					},
					{
						name:'分享量',
						type:'line',
						data:this.shareVolumeList
					},
					{
						name: '转发量',
						type: 'line',
						data:this.forwardVolumeList
					},
					{
						name:'评论量',
						type:'line',
						data:this.commentVolumeList
					},
					{
						name: '收藏量',
						type: 'line',
						data:this.collectionVolumeList
					}
				]
				myChart.setOption({
					title: {},
					tooltip: {},
					legend: {
					    data:dataType
					},
					grid: {x: 60, x2: 20},
					toolbox: {
					  show: false,
					  feature: {
					    mark: { show: true },
					    dataView: { show: true, readOnly: false },
					    magicType: { show: true, type: ['line', 'bar', 'stack', 'tiled'] },
					    restore: { show: true },
					    saveAsImage: { show: true }
					  }
					},
					calculable: true,
					xAxis: {
						data:this.daysArr
					},
					yAxis: {},
					series: series
				});
			},
			search(num){
				this.currentDate = num
				// if(this.type == 2){
				// 	this.pageNum = 1
				// 	this.pageSize = 10
				// 	this.total = 0
				// 	this.tableData = []
				// 	this.getList()
				// 	return
				// }
				// this.getDataToday()
				this.getData()
				let timeInterval = JSON.parse(JSON.stringify(this.timeInterval))
				if(num!=4){
					this.timeInterval = []
				}
				let data = {
					articleId:this.$route.params.id
				}
				data.beginDate = num==1?getRecentDays(7)[0]:num==2?getRecentDays(14)[0]:num==3?getRecentDays(30)[0]:timeInterval[0]
				data.endDate = num!=4?this.countDate[1]:timeInterval[1]
				articleDailyStatisticsDaily({data}).then(res => {
					if(res&&res.success){
						this.exPorts = {
							beginDate:data.beginDate,
							endDate:data.endDate
						}
						let list = res.data.list
						let readVolumeList = []
						let likeVolumeList = []
						let shareVolumeList = []
						let forwardVolumeList = []
						let commentVolumeList = []
						let collectionVolumeList = []
						let daysArr = []
						list.forEach(item=>{
							readVolumeList.push(item.readVolume)
							likeVolumeList.push(item.likeVolume)
							shareVolumeList.push(item.shareVolume)
							forwardVolumeList.push(item.forwardVolume)
							commentVolumeList.push(item.commentVolume)
							collectionVolumeList.push(item.collectionVolume)
							daysArr.push(item.statisticsDate)
						})
						this.readVolumeList = readVolumeList
						this.likeVolumeList = likeVolumeList
						this.shareVolumeList = shareVolumeList
						this.forwardVolumeList = forwardVolumeList
						this.commentVolumeList = commentVolumeList
						this.collectionVolumeList = collectionVolumeList
						this.daysArr = daysArr
						res.data.commentVolumeTotalSurround = Math.abs(res.data.commentVolumeSurround) - Math.abs(res.data.deleteCommentVolumeSurround)
						res.data.likeVolumeTotalSurround = Math.abs(res.data.likeVolumeSurround) - Math.abs(res.data.unlikeVolumeSurround)
						res.data.collectionVolumeTotalSurround = Math.abs(res.data.collectionVolumeSurround) - Math.abs(res.data.cancelCollectionVolumeSurround)
						this.dataInfo = res.data
						this.init()
					}
				})
			},
			handleSizeChange(val) {
				this.pageSize = val;
				this.getData()
			},
			handleCurrentChange(val) {
				this.pageNum = val;
				this.getData()
			},
			disabledDate(time) {
				// 当前日期减去半年
				const halfYearAgo = new Date();
				halfYearAgo.setMonth(halfYearAgo.getMonth() - 6);
				// 如果选中的日期小于半年前的日期，则禁用
				return time.getTime() < halfYearAgo.getTime() || time.getTime() > Date.now();
			}
		}
	}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
	.cardCon{
		max-width: 90%;
		margin-bottom: 10px;
	}
	.marginR60{margin-right: 60px;}
	.color666{color: #666;}
	.dataType{
		padding: 4px 0;
		border-bottom: 3px solid transparent;
		box-sizing: border-box;
		font-size:14px;
		color: #333;
		margin-right: 20px;
	}
	.dataActive{
		border-bottom: 3px solid #112DF2;
		box-sizing: border-box;
	}
	// :deep(.el-select .el-input__wrapper){
	// 	width: 200px;
	// }
	// :deep(.el-icon svg){
	// 	color: #112DF2;
	// 	width: 12px;
	// 	height: 12px;
	// }
	// .el-select-dropdown__item{
	//   padding: 0 20px;
	//   box-sizing: border-box;
	// }
</style>