<template>
	<div class="el-dialog-body-spacing">
		<el-dialog title="重置密码" :model-value="dialogVisible" width="600px" :before-close="hideDialog"
			:close-on-click-modal='false' append-to-body>
			<div style="position: relative">
				<el-form :model="pushData" :rules="rules" ref="formBox" label-width="120px">
					<el-form-item label="新密码" prop="newPwd">
						<el-input v-model="pushData.newPwd" autocomplete="new-password" type="password" show-password placeholder="请输入新密码" style="width: 85%"></el-input>
					</el-form-item>
					<el-form-item label="确认新密码" prop="password">
						<el-input v-model="pushData.password" autocomplete="new-password" type="password" placeholder="请再次输入新密码" style="width: 85%"></el-input>
					</el-form-item>
				</el-form>
			</div>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="hideDialog">取 消</el-button>	
					<el-button color='#112DF2' type="primary" @click="confirm">确 定</el-button>
				</span>
			</template>
		</el-dialog>
	</div>
</template>
<script>
	import Validate from '@/utils/rules'
	import {employeeUpdatePasswordById} from '@/api'
	export default {
		props: {
			visiFlag: {
				type: Function,
				default: function() {}
			},
			recordObject:{
				type:Object,
				default:{}
			},
			dialogVisible: {
				type: Boolean,
				default: false
			},
		},
		data() {	
			return {
				pushData:{
					password:'',
					newPwd:''
				},
				rules: {
					newPwd: [{required: true,message: '请输入8-14位数密码(英文、数字)',validator: Validate.pwdRule,trigger: "blur"}],
					password: [{required: true,message: "请输入8-14位数密码(英文、数字)",validator: Validate.pwdRule,trigger: "blur"}]
				},
				isRefresh:false
			}
		},
		watch: {
			dialogVisible(v) {
				this.pushData = {
					password:'',
					newPwd:'',
					id :''
				}
				if (v){
					this.pushData = {
						password:'',
						newPwd:'',
						id:this.recordObject.id
					}
				}
			}
		},
		methods: {
			checkPwd(){
				if(this.pushData.password && this.pushData.newPwd){
					if(this.pushData.password !== this.pushData.newPwd){
						this.$message.error('两次输入密码不一致')
						// this.checkPwdVal = false
					}
				}
			},
			confirm(){
				this.$refs.formBox.validate((valid) => {
					if (valid) {
						if(this.pushData.password !== this.pushData.newPwd){
							this.$message.error('两次输入密码不一致')
							return
						}
						let data = {
							id : this.pushData.id,
							password : this.pushData.password
						}
						employeeUpdatePasswordById({data}).then(res=>{
							
							if(res.success){
								this.isRefresh = true
								this.hideDialog()
							}else{
								this.loading = false
								this.$message.error(res.msg)
							}
						})
						
					}
				})
			},
			hideDialog() {
				this.visiFlag(false, this.isRefresh)
			}
		}
	}
</script>
<style rel="stylesheet/scss" lang='scss' scoped>
	.el-dialog__body{
		overflow: hidden;
		overflow-y:auto;
	}	
</style>
