import Http from '@/api/http'
import request from '@/api/http1'
import requestform from '@/api/httpform'
let h = new Http().create()
let domain = new Http().upload()

// 认证授权模块
export const userLogin = d => h.post('/authorization-api/authenticate/token', d) //用户登录
export const logout = d => h.get('/authorization-api/authenticate/logout', d) //退出登录
// export const userInfo = d => h.post('/user/getById', d) //根据用户ID查询用户信息
// 用户
export const userPageList = d => h.post('/user-api/customer/ps-pageList', d) //查询会员列表（分页）
export const exportUserList = domain + '/user-api/customer/ps-export' //导出会员列表
// 会员更新备注
export function customerUpdateRemarkById (config = {}) {
  return requestform({
    url: '/user-api/customer/ps-updateRemarkById',
    method: 'post',
    ...config
  })
}
// 商品模块
// 类目
export const uploadUrl = domain + "/goods-api/file/ps-upload" //类目文件上传
export const goodsCategoryCreate = d => h.post('/goods-api/goods-category/ps-create', d) //创建商品类目
export const goodsCategoryUpdate = d => h.post('/goods-api/goods-category/ps-updateById', d) //根据ID更新商品类目信息
export const goodsCategoryPageList = d => h.post('/goods-api/goods-category/ps-pageList', d) //分页查询商品类目列表
export const goodsCategoryTreeList = d => h.get('/goods-api/goods-category/ps-treeList',d) // 查询树状结构商品类目列表
// 启用/禁用商品类目
export function goodsCategoryUpdateEnabledById (config = {}) {
  return requestform({
    url: '/goods-api/goods-category/ps-updateEnabledById',
    method: 'post',
    ...config
  })
}
// 删除商品类目
export function goodsCategoryDeletedById (config = {}) {
  return requestform({
    url: '/goods-api/goods-category/ps-deletedById',
    method: 'post',
    ...config
  })
}
// 查询商品类目列表
export function goodsCategoryList (config = {}) {
  return request({
    url: '/goods-api/goods-category/ps-list',
    method: 'post',
    ...config
  })
}
// 根据ID查询商品类目信息
export function goodsCategoryInfo (config = {}) {
  return request({
    url: '/goods-api/goods-category/ps-getById',
    method: 'get',
    ...config
  })
}
// 商品
export const goodsCreate = d => h.post('/goods-api/goods/ps-create', d) //创建商品
export const goodsPageList = d => h.post('/goods-api/goods/ps-pageList', d) //分页查询商品列表
export const goodsList = d => h.post('/goods-api/goods/ps-list', d) //查询商品列表
export const goodsUpdate = d => h.post('/goods-api/goods/ps-updateById', d) //更新商品信息
// 根据商品ID更新商品虚拟销量
export function goodsUpdateVirtualSalesVolumeById (config = {}) {
  return requestform({
    url: '/goods-api/goods/ps-updateVirtualSalesVolumeById',
    method: 'post',
    ...config
  })
}
// 逻辑删除商品
export function goodsDel (config = {}) {
  return requestform({
    url: '/goods-api/goods/ps-deleteById',
    method: 'post',
    ...config
  })
}
// 启用/禁用商品
export function goodsUpdateEnabledById (config = {}) {
  return requestform({
    url: '/goods-api/goods/ps-updateEnabledById',
    method: 'post',
    ...config
  })
}
// 根据商品ID更新商品销售状态
export function goodsUpdateSaleableById (config = {}) {
  return requestform({
    url: '/goods-api/goods/ps-updateSaleableById',
    method: 'post',
    ...config
  })
}
// 根据商品ID查询商品信息
export function goodsInfo (config = {}) {
  return request({
    url: '/goods-api/goods/ps-getById',
    method: 'get',
    ...config
  })
}
export const cardCreate = d => h.post('/goods-api/card/ps-create', d) //创建卡
export const statisticsCardCountByCardType = d => h.get('/goods-api/card/ps-statisticsCardCountByCardType', d) //查询卡分类对应的卡数量
export const cardPageList = d => h.post('/goods-api/card/ps-pageList', d) //分页查询卡列表
export const cardList = d => h.post('/goods-api/card/ps-list', d) //查询卡列表
export const cardUpdate = d => h.post('/goods-api/card/ps-updateById', d) //更新卡信息
// 根据卡ID更新卡虚拟销量
export function cardUpdateVirtualSalesVolumeById (config = {}) {
  return requestform({
    url: '/goods-api/card/ps-updateVirtualSalesVolumeById',
    method: 'post',
    ...config
  })
}
// 逻辑删除卡
export function cardDel (config = {}) {
  return requestform({
    url: '/goods-api/card/ps-deleteById',
    method: 'post',
    ...config
  })
}
// 启用/禁用卡
export function cardUpdateEnabledById (config = {}) {
  return requestform({
    url: '/goods-api/card/ps-updateEnabledById',
    method: 'post',
    ...config
  })
}
// 根据卡ID更新卡销售状态
export function cardUpdateSaleableById (config = {}) {
  return requestform({
    url: '/goods-api/card/ps-updateSaleableById',
    method: 'post',
    ...config
  })
}
// 根据卡ID查询卡信息
export function cardInfo (config = {}) {
  return request({
    url: '/goods-api/card/ps-getById',
    method: 'get',
    ...config
  })
}
// 推广管理
export const merchantSpreadSettlementCreate = d => h.post('/marketing-api/merchant-spread-settlement-receipts/ps-create', d) //创建商户推广结算单据
export const merchantSpreadSettlementExport = domain + '/marketing-api/merchant-spread-settlement-receipts-particular/ps-export' //导出商户推广结算单据明细
// 查询商户推广结算单据详情
export function merchantSpreadSettlementGetById (config = {}) {
  return request({
    url: '/marketing-api/merchant-spread-settlement-receipts/ps-getById',
    method: 'get',
    ...config
  })
}
export const merchantSpreadSettlementPageList = d => h.post('/marketing-api/merchant-spread-settlement-receipts/ps-pageList', d) //分页查询商户推广结算单据列表
export const merchantSpreadParticularPageList = d => h.post('/marketing-api/merchant-spread-particular/ps-pageList', d) //分页查询商户推广明细列表
export const merchantSpreadParticularExport = domain + '/marketing-api/merchant-spread-particular/ps-export' //导出商户推广明细列表
// 更新商户推广明细承接商户
export function merchantSpreadParticularUpdateUndertakingMerchantById (config = {}) {
  return requestform({
    url: '/marketing-api/merchant-spread-particular/ps_updateUndertakingMerchantById',
    method: 'post',
    ...config
  })
}
// 更新商户推广明细承接商户客资费
export function merchantSpreadParticularUpdateUndertakingMerchantGuestRechargeAmountById (config = {}) {
  return requestform({
    url: '/marketing-api/merchant-spread-particular/ps_updateUndertakingMerchantGuestRechargeAmountById',
    method: 'post',
    ...config
  })
}
export const guestParticularCreat = d => h.post('/marketing-api/merchant-spread-guest-particular/ps-create', d) //创建商户客资明细
export const guestParticularPageList = d => h.post('/marketing-api/merchant-spread-guest-particular/ps-pageList', d) //分页查询商户客资明细列表
export const guestParticularExport = domain + '/marketing-api/merchant-spread-guest-particular/ps-export' //导出商户客资明细列表
// 更新商户客资明细会员名称
export function guestParticularUpdateCustomerNameById (config = {}) {
  return requestform({
    url: '/marketing-api/merchant-spread-guest-particular/ps-updateCustomerNameById',
    method: 'post',
    ...config
  })
}
// 更新商户客资明细会员手机号
export function guestParticularUpdateCustomerPhoneNumberById (config = {}) {
  return requestform({
    url: '/marketing-api/merchant-spread-guest-particular/ps-updateCustomerPhoneNumberById',
    method: 'post',
    ...config
  })
}
// 更新商户客资明细客资费
export function guestParticularUpdateUndertakingMerchantGuestRechargeAmountById (config = {}) {
  return requestform({
    url: '/marketing-api/merchant-spread-guest-particular/ps-updateUndertakingMerchantGuestRechargeAmountById',
    method: 'post',
    ...config
  })
}
// 新增商户推广小程序二维码
export function wechatMaQrcodeCreat (config = {}) {
  return requestform({
    url: '/marketing-api/merchant-spread-wechat-ma-qrcode/ps-create',
    method: 'post',
    ...config
  })
}
// 删除商户推广小程序二维码
export function wechatMaQrcodeDeleteById (config = {}) {
  return requestform({
    url: '/marketing-api/merchant-spread-wechat-ma-qrcode/ps-deleteById',
    method: 'post',
    ...config
  })
}
// 启用/禁用商户推广小程序二维码
export function wechatMaQrcodeEnabledById (config = {}) {
  return requestform({
    url: '/marketing-api/merchant-spread-wechat-ma-qrcode/ps-updateEnabledById',
    method: 'post',
    ...config
  })
}
// 更新推广单价
export function wechatMaQrcodeUpdateSpreadUnitPriceById (config = {}) {
  return requestform({
    url: '/marketing-api/merchant-spread-wechat-ma-qrcode/ps-updateSpreadUnitPriceById',
    method: 'post',
    ...config
  })
}
export const wechatMaQrcodePageList = d => h.post('/marketing-api/merchant-spread-wechat-ma-qrcode/ps-pageList', d) //分页查询商户推广小程序二维码列表
// 查询已创建商户推广小程序二维码的门店ID
export function wechatMaCreatedListShopId (config = {}) {
  return request({
    url: '/marketing-api/merchant-spread-wechat-ma-qrcode/ps-listShopIdWhichWechatMpQrcodeHasBeenCreated',
    method: 'get',
    ...config
  })
}
export const merchantSpreadWechatMaQrcodePageList = d => h.post('/marketing-api/merchant-spread-wechat-ma-user/ps-pageList', d) //分页查询商户推广小程序用户列表
export const merchantSpreadWechatMaExport = domain + '/marketing-api/merchant-spread-wechat-ma-user/ps-export' //导出商户推广小程序用户列表
// 新增商户推广公众号二维码
export function wechatMpQrcodeCreat (config = {}) {
  return requestform({
    url: '/marketing-api/merchant-spread-wechat-mp-qrcode/ps-create',
    method: 'post',
    ...config
  })
}
// 删除商户推广公众号二维码
export function wechatMpQrcodeDeleteById (config = {}) {
  return requestform({
    url: '/marketing-api/merchant-spread-wechat-mp-qrcode/ps-deleteById',
    method: 'post',
    ...config
  })
}
// 启用/禁用商户推广公众号二维码
export function wechatMpQrcodeUpdateEnabledById (config = {}) {
  return requestform({
    url: '/marketing-api/merchant-spread-wechat-mp-qrcode/ps-updateEnabledById',
    method: 'post',
    ...config
  })
}
// 更新推广单价
export function wechatMpQrcodeUpdateSpreadUnitPriceById (config = {}) {
  return requestform({
    url: '/marketing-api/merchant-spread-wechat-mp-qrcode/ps-updateSpreadUnitPriceById',
    method: 'post',
    ...config
  })
}
export const wechatMpQrcodePageList = d => h.post('/marketing-api/merchant-spread-wechat-mp-qrcode/ps-pageList', d) //分页查询商户推广公众号二维码列表
// 查询已创建商户推广公众号二维码的门店ID
export function wechatMpCreatedListShopId (config = {}) {
  return request({
    url: '/marketing-api/merchant-spread-wechat-mp-qrcode/ps-listShopIdWhichWechatMpQrcodeHasBeenCreated',
    method: 'get',
    ...config
  })
}
export const exportFile = domain + '/cms-api/wechat-mp/user/ps-export' //导出微信公众号用户列表
export const wechatMpUserPageList = d => h.post('/cms-api/wechat-mp/user/ps-pageList', d) //分页查询微信公众号用户列表
export const merchantSpreadConfigCreate = d => h.post('/marketing-api/merchant-spread-config/ps-create', d) //创建商户推广配置
export const merchantSpreadConfigUpdate = d => h.post('/marketing-api/merchant-spread-config/ps-updateById', d) //更新商户推广配置
// 删除商户推广配置
export function merchantSpreadConfigDel (config = {}) {
  return requestform({
    url: '/marketing-api/merchant-spread-config/ps-deleteById',
    method: 'post',
    ...config
  })
}
// 查询商户推广配置列表
export function merchantSpreadConfigList (config = {}) {
  return request({
    url: '/marketing-api/merchant-spread-config/ps-list',
    method: 'get',
    ...config
  })
}
// 查询已创建推广配置的商户ID
export function merchantSpreadConfigShopIdList (config = {}) {
  return request({
    url: '/marketing-api/merchant-spread-config/ps-listMerchantIdWhichSpreadConfigHasBeenCreated',
    method: 'get',
    ...config
  })
}
// 查询推广渠道列表
export function spreadChannelList (config = {}) {
  return request({
    url: '/marketing-api/spread-channel/ps-list',
    method: 'get',
    ...config
  })
}
export const spreadQrcodeCreate = d => h.post('/marketing-api/spread-qrcode/ps-create', d) //创建推广码
export const spreadQrcodePageList = d => h.post('/marketing-api/spread-qrcode/ps-pageList', d) //分页查询推广码列表
export const spreadQrcodeUpdateById = d => h.post('/marketing-api/spread-qrcode/ps-updateById', d) //根据推广码ID更新推广码信息
// 启用/禁用推广码
export function spreadQrcodeUpdateEnabledById (config = {}) {
  return requestform({
    url: '/marketing-api/spread-qrcode/ps-updateEnabledById',
    method: 'post',
    ...config
  })
}
// 根据推广码ID查询推广码信息
export function spreadQrcodeUpdateGetById (config = {}) {
  return request({
    url: '/marketing-api/spread-qrcode/ps-getById',
    method: 'get',
    ...config
  })
}
export const spreadQrcodeDataPageList = d => h.post('/marketing-api/spread-qrcode-data/ps-pageList', d) //分页查询推广码数据列表
export const spreadQrcodeExport = domain + '/marketing-api/spread-qrcode-data/ps-export' //导出推广码数据列表
// 推广数据统计
export const spreadQrcodeDataStatistics = d => h.post('/marketing-api/spread-qrcode-data/ps-statistics', d)
// export function spreadQrcodeDataStatistics (config = {}) {
//   return request({
//     url: '/marketing-api/spread-qrcode-data/ps-statistics',
//     method: 'get',
//     ...config
//   })
// }
export const spreadQrcodeDataChartAnalyse = d => h.post('/marketing-api/spread-qrcode-data/ps-chart-analyse', d) //推广数据图表分析
// 活动模块
// 活动
export const activityCreate = d => h.post('/marketing-api/activity/ps-create', d) //创建活动
export const activityUpdate = d => h.post('/marketing-api/activity/ps-updateById', d) //更新活动信息
export const activityPageList = d => h.post('/marketing-api/activity/ps-pageList', d) //分页查询活动列表
// 根据活动ID查询活动信息
export function activityInfo (config = {}) {
  return request({
    url: '/marketing-api/activity/ps-getById',
    method: 'get',
    ...config
  })
}
// 更新活动商品虚拟销量
export function activityGoodsUpdateVirtualSalesVolumeById (config = {}) {
  return requestform({
    url: '/marketing-api/activity-goods/ps-updateVirtualSalesVolumeById',
    method: 'post',
    ...config
  })
}
export const pageListByActivityId = d => h.post('/marketing-api/activity-goods/ps-pageListByActivityId', d) //根据活动ID及条件分页查询活动商品列表
// 体验价活动
export const experiencePriceCreate = d => h.post('/marketing-api/experience-price-activity/ps-batchCreate', d) //批量创建体验价活动
export const experiencePricePageList = d => h.post('/marketing-api/experience-price-activity/ps-pageList', d) //分页查询体验价活动列表
// 启用/禁用活动
export function experienceUpdateEnabledById (config = {}) {
  return requestform({
    url: '/marketing-api/experience-price-activity/ps-updateEnabledById',
    method: 'post',
    ...config
  })
}
// 首页配置
export const homepageConfigCreate = d => h.post('/marketing-api/homepage-config/ps-create', d) //创建首页配置
export const homepageConfigUpdateById = d => h.post('/marketing-api/homepage-config/ps-updateById', d) //根据首页配置ID更新首页配置
export const homepageConfigPageList = d => h.post('/marketing-api/homepage-config/ps-pageList', d) //查询首页配置列表（分页）
// export function homepageConfigPageList (config = {}) {
//   return request({
//     url: '/marketing-api/homepage-config/ps-pageList',
//     method: 'get',
//     ...config
//   })
// }
//根据首页配置ID查询首页配置详情
export function homepageConfigInfo (config = {}) {
  return request({
    url: '/marketing-api/homepage-config/ps-getById',
    method: 'get',
    ...config
  })
}
// 启用/禁用首页配置
export function  homepageConfigUpdateEnabledById (config = {}) {
  return requestform({
    url: '/marketing-api/homepage-config/ps-updateEnabledById',
    method: 'post',
    ...config
  })
}
// 删除首页配置
export function  homepageConfigDeleteById (config = {}) {
  return requestform({
    url: '/marketing-api/homepage-config/ps-deleteById',
    method: 'post',
    ...config
  })
}
// 金刚区
export const activityUploadUrl = domain + "/marketing-api/file/ps-upload" //金刚区文件上传
export const diamondRegionCreate = d => h.post('/marketing-api/diamond-region/ps-create', d) //创建金刚区
export const diamondRegionPageList = d => h.post('/marketing-api/diamond-region/ps-pageList', d) //分页查询金刚区列表
export const diamondRegionList = d => h.post('/marketing-api/diamond-region/ps-list', d) //查询金刚区列表
// 根据ID查询金刚区信息
export function diamondRegionInfo (config = {}) {
  return request({
    url: '/marketing-api/diamond-region/ps-getById',
    method: 'get',
    ...config
  })
}
export const publicWelfareActivity = d => h.post('/marketing-api/public-welfare-activity-participation/ps-pageList', d) //分页查询公益活动参与记录列表
export const postpartumCareActivity = d => h.post('/marketing-api/postpartum-care-activity-participation/ps-pageList', d) //分页查询产后关爱活动参与记录列表
export const maternityBenefitActivity = d => h.post('/marketing-api/maternity-benefit-activity-participation/ps-pageList', d) //分页查询孕产福利活动参与记录列表
export const appointGiftActivity = d => h.post('/marketing-api/appointment-gift-activity-participation/ps-pageList', d) //分页查询预约礼活动参与记录列表
export const maternityBenefitActivityExport = domain + "/marketing-api/maternity-benefit-activity-participation/ps-export" //导出孕产福利活动参与记录列表
export const attentionGiftActivityParticipation = d => h.post('/marketing-api/attention-gift-activity-participation/ps-pageList', d) //分页查询关注礼活动参与记录列表
export const attentionGiftActivityParticipationExport = domain + "/marketing-api/attention-gift-activity-participation/ps-export" //导出关注礼活动参与记录列表
// 更新预约礼活动参与记录会员昵称
export function updateCustomerNickNameById (config = {}) {
  return requestform({
    url: '/marketing-api/appointment-gift-activity-participation/ps-updateCustomerNickNameById',
    method: 'post',
    ...config
  })
}
// 组织机构模块
// 商家
export const depUploadUrl = domain + "/organization-api/file/ps-upload" //组织机构文件上传
export const merchantCreate = d => h.post('/organization-api/merchant/ps-create', d) //创建商家
export const merchantUpdate = d => h.post('/organization-api/merchant/ps-updateById', d) //根据ID更新商家信息
export const merchantUpdateEnabledById = d => h.post('/organization-api/merchant/ps-updateEnabledById', d) //启用/禁用商家
export const merchantPageList = d => h.post('/organization-api/merchant/ps-pageList', d) //分页查询商家列表
export const merchantList = d => h.post('/organization-api/merchant/ps-list', d) //查询商家列表
// 根据商家ID查询商家信息
export function merchantInfo (config = {}) {
  return request({
    url: '/organization-api/merchant/ps-getById',
    method: 'get',
    ...config
  })
}
// 门店
export const shopCreate = d => h.post('/organization-api/shop/ps-create', d) //创建门店
export const shopUpdate = d => h.post('/organization-api/shop/ps-updateById', d) //根据ID更新门店信息
export const shopUpdateEnabledById = d => h.post('/organization-api/shop/ps-updateEnabledById', d) //启用/禁用门店
export const shopUpdateDisplayById = d => h.post('/organization-api/shop/ps-updateDisplayById', d) //展示/隐藏门店
export const shopPageList = d => h.post('/organization-api/shop/ps-pageList', d) //分页查询门店列表
export const shopList = d => h.post('/organization-api/shop/ps-list', d) //查询门店列表
// 根据门店ID查询门店信息
export function shopInfo (config = {}) {
  return request({
    url: '/organization-api/shop/ps-getById',
    method: 'get',
    ...config
  })
}
// 更新订单备注
export function orderUpdateRemarkById (config = {}) {
  return requestform({
    url: '/order-api/orders/ps-updateRemarkById',
    method: 'post',
    ...config
  })
}
export const orderPageList = d => h.post('/order-api/orders/ps-pageList', d) //分页查询订单列表
// 分配门店
export function orderChooseServiceShop (config = {}) {
  return requestform({
    url: '/order-api/orders/ps-distribute',
    method: 'post',
    ...config
  })
}
//根据订单ID查询订单详情
export function ordersInfo (config = {}) {
  return request({
    url: '/order-api/orders/ps-getById',
    method: 'get',
    ...config
  })
}
// 知识课堂
export const tagPageList = d => h.post('/cms-api/tag/ps-pageList', d) //分页查询标签列表
// 创建标签
export function tagPsCreate (config = {}) {
  return requestform({
    url: '/cms-api/tag/ps-create',
    method: 'post',
    ...config
  })
}
// 删除标签
export function tagPsDeleteByIds (config = {}) {
  return requestform({
    url: '/cms-api/tag/ps-deleteByIds',
    method: 'post',
    ...config
  })
}
// 根据标签ID更新标签
export function tagPsUpdateById (config = {}) {
  return requestform({
    url: '/cms-api/tag/ps-updateById',
    method: 'post',
    ...config
  })
}
// 启用/禁用标签
export function tagUpdateEnabledById (config = {}) {
  return requestform({
    url: '/cms-api/tag/ps-updateEnabledById',
    method: 'post',
    ...config
  })
}
//查询标签列表
export function tagList (config = {}) {
  return request({
    url: '/cms-api/tag/ps-list',
    method: 'get',
    ...config
  })
}
export const categoryDeleteByIds = d => h.post('/cms-api/category/ps-deleteByIds', d) //删除分类
export const categoryPsCreate = d => h.post('/cms-api/category/ps-create', d) //创建分类
//查询顶级分类列表
export function categoryTopLevelList (config = {}) {
  return request({
    url: '/cms-api/category/ps-topLevelList',
    method: 'get',
    ...config
  })
}
//查询树状结构分类列表
export function categoryTreeList (config = {}) {
  return request({
    url: '/cms-api/category/ps-treeList',
    method: 'get',
    ...config
  })
}
// 启用/禁用分类
export function categoryUpdateEnabledById (config = {}) {
  return requestform({
    url: '/cms-api/category/ps-updateEnabledById',
    method: 'post',
    ...config
  })
}
// 更新分类
export function categoryUpdateById (config = {}) {
  return requestform({
    url: '/cms-api/category/ps-updateById',
    method: 'post',
    ...config
  })
}
export const employeePsCreate = d => h.post('/user-api/employee/ps-create', d) //创建员工
export const employeePsUpdateById = d => h.post('/user-api/employee/ps-updateById', d) //根据员工ID更新员工信息
export const employeePageList = d => h.post('/user-api/employee/ps-pageList', d) //分页查询员工列表
export const employeeDeleteByIds = d => h.post('/user-api/employee/ps-deleteByIds', d) //根据员工ID删除员工
//根据员工ID查询员工信息
export function employeeGetById (config = {}) {
  return request({
    url: '/user-api/employee/ps-getById',
    method: 'get',
    ...config
  })
}
// 更新员工密码
export function employeeUpdatePasswordById (config = {}) {
  return requestform({
    url: '/user-api/employee/ps-updatePasswordById',
    method: 'post',
    ...config
  })
}
// 更新员工绑定手机号
export function employeeUpdatePhoneNumberById (config = {}) {
  return requestform({
    url: '/user-api/employee/ps-updatePhoneNumberById',
    method: 'post',
    ...config
  })
}
export const articlePageList = d => h.post('/cms-api/article/ps-pageList', d) //分页查询文章列表
export const articleDraft = d => h.post('/cms-api/article/ps-draft', d) //创建草稿
export const articleRedraft = d => h.post('/cms-api/article/ps-redraft', d) //保存草稿
export const articlePublish = d => h.post('/cms-api/article/ps-publish', d) //发布文章
export const articleRepublish = d => h.post('/cms-api/article/ps-republish', d) //重新发布
export const articleSimplePageList = d => h.post('/cms-api/article/ps-simplePageList', d) //简单分页查询文章列表
export const articleRankingByCondition = d => h.post('/cms-api/article/ps-rankingByCondition', d) //分页查询文章阅读数量排名列表
export const fileUploadUrl = domain + "/cms-api/file/ps-upload" //文件上传
//根据文章ID查询文章信息
export function articleGetById (config = {}) {
  return request({
    url: '/cms-api/article/ps-getById',
    method: 'get',
    ...config
  })
}
// 更新虚拟数量
export function articleUpdateVirtualVolumeById (config = {}) {
  return requestform({
    url: '/cms-api/article/ps-updateVirtualVolumeById',
    method: 'post',
    ...config
  })
}
// 删除文章
export function articleDeleteByIds (config = {}) {
  return requestform({
    url: '/cms-api/article/ps-deleteByIds',
    method: 'post',
    ...config
  })
}
// 取消发布
export function articleCancelPublished (config = {}) {
  return requestform({
    url: '/cms-api/article/ps-cancelPublished',
    method: 'post',
    ...config
  })
}
// 置顶/取消置顶
export function articleUpdateIsTopById (config = {}) {
  return requestform({
    url: '/cms-api/article/ps-updateIsTopById',
    method: 'post',
    ...config
  })
}
//查询今日文章数据统计
export function articleDailyStatisticsToday (config = {}) {
  return request({
    url: '/cms-api/article-daily-statistics/ps-today',
    method: 'get',
    ...config
  })
}
//查询每日文章数据统计
export function articleDailyStatisticsDaily (config = {}) {
  return request({
    url: '/cms-api/article-daily-statistics/ps-daily',
    method: 'get',
    ...config
  })
}
export const articleDailyStatisticsExportDaily = domain + '/cms-api/article-daily-statistics/ps-exportDaily' //导出每日文章数据统计列表
export const articleDailyStatisticsPageListDaily = d => h.post('/cms-api/article-daily-statistics/ps-pageListDaily', d) //分页查询每日文章数据统计列表
export const articleCommentPageList = d => h.post('/cms-api/article-comment/ps-pageList', d) //分页查询评论列表
export const articleCommentReplyPageList = d => h.post('/cms-api/article-comment-reply/ps-pageList', d) //分页查询评论回复列表
//查询最新评论列表
export function articleCommentNewestPageList (config = {}) {
  return request({
    url: '/cms-api/article-comment/ps-newestPageList',
    method: 'get',
    ...config
  })
}
//删除评论
export function articleCommentDeleteById (config = {}) {
  return requestform({
    url: '/cms-api/article-comment/ps-deleteById',
    method: 'post',
    ...config
  })
}
//置顶/取消置顶评论
export function articleCommentTop (config = {}) {
  return requestform({
    url: '/cms-api/article-comment/ps-top',
    method: 'post',
    ...config
  })
}
//评论
export function articleComment (config = {}) {
  return requestform({
    url: '/cms-api/article-comment/ps-comment',
    method: 'post',
    ...config
  })
}
//回复评论
export function replyComment (config = {}) {
  return requestform({
    url: '/cms-api/article-comment-reply/ps-replyComment',
    method: 'post',
    ...config
  })
}
//回复评论回复
export function replyCommentReply (config = {}) {
  return requestform({
    url: '/cms-api/article-comment-reply/ps-replyCommentReply',
    method: 'post',
    ...config
  })
}
//删除评论回复
export function articleCommentReplyDeleteById (config = {}) {
  return requestform({
    url: '/cms-api/article-comment-reply/ps-deleteById',
    method: 'post',
    ...config
  })
}