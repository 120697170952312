<template>
	<div :class="['content',isCollapse?'menu--fold':'menu--unfold']">
		<!-- 侧边菜单栏 -->
		<div class="menuLeft">
			<div class="menu-nav-header">
				<!-- <span>{{isCollapse?'控制台':'管理控制台'}}</span> -->
				<img class="logo" src="@/assets/images/logo2.png" alt="">
			</div>
			 <!--todo 菜单栏组件 -->
			 <!--todo 菜单栏组件 -->
			<!-- <el-menu active-text-color="#fff" background-color="#112DF2" class="el-menu-vertical-demo"
			 	:collapse-transition="true" :unique-opened='true' :default-active="defaultActive" text-color="#fff " @open="handleOpen"
			 	@close="handleClose" :collapse="isCollapse"> -->
			<el-menu active-text-color="#fff" background-color="#112DF2" class="el-menu-vertical-demo"
				:collapse-transition="true" :default-active="defaultActive" text-color="#fff " @open="handleOpen"
				@close="handleClose" :collapse="isCollapse">
				<el-sub-menu index="1">
					<template #title>
						<el-icon><Shop /></el-icon>
						<span slot="">&nbsp;&nbsp;商户管理</span>
					</template>
					<el-menu-item index="1-1" @click="$router.push('/merchantManagement')">
						<SvgIcon name="img" class="icon-svg" />
						<span>&nbsp;&nbsp;商户管理</span>
					</el-menu-item>
					<el-menu-item index="1-2" @click="$router.push('/merchantManagement/storeManagement')">
						<SvgIcon name="img" class="icon-svg" />
						<span>&nbsp;&nbsp;门店管理</span>
					</el-menu-item>
				</el-sub-menu>
				<el-sub-menu index="2">
					<template #title>
						<el-icon><User /></el-icon>
						<span>&nbsp;&nbsp;用户管理</span>
					</template>
					<el-menu-item index="2-1" @click="$router.push('/user/userList')">
						<SvgIcon name="img" class="icon-svg" />
						<span>&nbsp;&nbsp;用户列表</span>
					</el-menu-item>
					<el-menu-item index="2-2" @click="$router.push('/user/employeeList')">
						<SvgIcon name="img" class="icon-svg" />
						<span>&nbsp;&nbsp;员工列表</span>
					</el-menu-item>
					<!-- <el-sub-menu index="2-4">
						<template #title>
							<SvgIcon name="img" class="icon-svg" />
							<span>&nbsp;&nbsp;图片3</span>
						</template>
						<el-menu-item index="2-4-1">
							<SvgIcon name="img" class="icon-svg" />
							<span>&nbsp;&nbsp;图片三级子菜单</span>
						</el-menu-item>	
					</el-sub-menu> -->
				</el-sub-menu>
				<el-sub-menu index="3">	
					<template #title>
						<el-icon><Present /></el-icon>
						<span>&nbsp;&nbsp;商品管理</span>
					</template>
					<el-menu-item index="3-1" @click="$router.push('/goods/retailGoodsList')">
						<span class="icon-text">&nbsp;&nbsp;零售商品</span>
					</el-menu-item>
					<el-menu-item index="3-2" @click="$router.push('/goods/itemsList')">
						<span class="icon-text">&nbsp;&nbsp;服务项目</span>
					</el-menu-item>
					<el-menu-item index="3-3" @click="$router.push('/goods/cardList')">
						<span class="icon-text">&nbsp;&nbsp;卡项</span>
					</el-menu-item>
				</el-sub-menu>
				<el-sub-menu index="4">
					<template #title>
						<el-icon><Tickets /></el-icon>
						<span>&nbsp;&nbsp;订单管理</span>
					</template>
					<el-menu-item index="4" @click="$router.push('/orderList')">
						<span class="icon-text">&nbsp;&nbsp;订单管理</span>
					</el-menu-item>
				</el-sub-menu>
				<el-sub-menu index="5">
					<template #title>
						<el-icon><Medal /></el-icon>
						<span>&nbsp;&nbsp;营销活动</span>
					</template>
					<el-menu-item index="5-1" @click="$router.push('/active/groupList')">
						<span class="icon-text">&nbsp;&nbsp;拼团活动</span>
					</el-menu-item>
					<!-- <el-menu-item index="5-2">
						<span class="icon-text">&nbsp;&nbsp;砍价活动</span>
					</el-menu-item> -->
					<el-menu-item index="5-3" @click="$router.push('/active/flashSaleList')">
						<span class="icon-text">&nbsp;&nbsp;秒杀活动</span>
					</el-menu-item>
					<!-- <el-menu-item index="5-4">
						<span class="icon-text">&nbsp;&nbsp;banner位</span>
					</el-menu-item> -->
					<!-- <el-menu-item index="5-5">
						<span class="icon-text">&nbsp;&nbsp;广告位</span>
					</el-menu-item> -->
					<el-menu-item index="5-6" @click="$router.push('/active/experienceList')">
						<span class="icon-text">&nbsp;&nbsp;体验价</span>
					</el-menu-item>
					<el-menu-item index="5-7" @click="$router.push('/active/diamondRegionList')">
						<span class="icon-text">&nbsp;&nbsp;首页配置</span>
					</el-menu-item>
					<el-menu-item index="5-8" @click="$router.push('/active/publicBenefit')">
						<span class="icon-text">&nbsp;&nbsp;公益母乳</span>
					</el-menu-item>
					<el-menu-item index="5-9" @click="$router.push('/active/postpartumCare')">
						<span class="icon-text">&nbsp;&nbsp;产后关爱</span>
					</el-menu-item>
					<el-menu-item index="5-10" @click="$router.push('/active/appointGift')">
						<span class="icon-text">&nbsp;&nbsp;预约礼</span>
					</el-menu-item>
					<el-menu-item index="5-11" @click="$router.push('/active/maternityBenefit')">
						<span class="icon-text">&nbsp;&nbsp;关爱母婴</span>
					</el-menu-item>
					<el-menu-item index="5-11" @click="$router.push('/active/attentionGift')">
						<span class="icon-text">&nbsp;&nbsp;关注礼</span>
					</el-menu-item>
				</el-sub-menu>
				<el-sub-menu index="6">
					<template #title>
						<el-icon><Notebook /></el-icon>
						<span>&nbsp;&nbsp;类目管理</span>
					</template>
					<el-menu-item index="6-1" @click="$router.push('/categoryList')">
						<span class="icon-text">&nbsp;&nbsp;类目列表</span>
					</el-menu-item>
				</el-sub-menu>
				<el-sub-menu index="7">
					<template #title>
						<el-icon><Coin /></el-icon>
						<span>&nbsp;&nbsp;推广管理</span>
					</template>
					<el-menu-item index="7-1" @click="$router.push('/distributionManagement/generalStatistics')">
						<span class="icon-text">&nbsp;&nbsp;推广统计</span>
					</el-menu-item>
					<el-menu-item index="7-2" @click="$router.push('/distributionManagement/accountDetails')">
						<span class="icon-text">&nbsp;&nbsp;客资明细</span>
					</el-menu-item>
					<el-menu-item index="7-3" @click="$router.push('/distributionManagement/promotionSettlement')">
						<span class="icon-text">&nbsp;&nbsp;推广结算</span>
					</el-menu-item>
					<el-menu-item index="7-4" @click="$router.push('/distributionManagement/extensionCodeList')">
						<span class="icon-text">&nbsp;&nbsp;推广码</span>
					</el-menu-item>
					<el-menu-item index="7-5" @click="$router.push('/distributionManagement/extensionCodeStatistics')">
						<span class="icon-text">&nbsp;&nbsp;推广码统计</span>
					</el-menu-item>
					<el-menu-item index="7-6" @click="$router.push('/distributionManagement/promotionSettings')">
						<span class="icon-text">&nbsp;&nbsp;推广设置</span>
					</el-menu-item>
					<el-menu-item index="7-7" @click="$router.push('/distributionManagement/officialAccountsCodeList')">
						<span class="icon-text">&nbsp;&nbsp;公众号推广码</span>
					</el-menu-item>
					<el-menu-item index="7-8" @click="$router.push('/distributionManagement/officialAccountsDataAnalysis')">
						<span class="icon-text">&nbsp;&nbsp;公众号推广分析</span>
					</el-menu-item>
				</el-sub-menu>
				<el-sub-menu index="8">
					<template #title>
						<el-icon><OfficeBuilding /></el-icon>
						<span>&nbsp;&nbsp;知识课堂</span>
					</template>
					<el-menu-item index="8-1" @click="$router.push('/knowledgeClassroom/publicationList')">
						<span class="icon-text">&nbsp;&nbsp;发布记录</span>
					</el-menu-item>
					<el-menu-item index="8-2" @click="$router.push('/knowledgeClassroom/dataAnalysis')">
						<span class="icon-text">&nbsp;&nbsp;数据分析</span>
					</el-menu-item>
					<!-- <el-menu-item index="8-3" @click="$router.push('/knowledgeClassroom/materialLibrary')">
						<span class="icon-text">&nbsp;&nbsp;素材库</span>
					</el-menu-item>
					<el-menu-item index="8-4" @click="$router.push('/knowledgeClassroom/advertisingSpace')">
						<span class="icon-text">&nbsp;&nbsp;广告位管理</span>
					</el-menu-item> -->
					<el-menu-item index="8-6" @click="$router.push('/knowledgeClassroom/classify')">
						<span class="icon-text">&nbsp;&nbsp;分类管理</span>
					</el-menu-item>
					<el-menu-item index="8-7" @click="$router.push('/knowledgeClassroom/tagManagement')">
						<span class="icon-text">&nbsp;&nbsp;标签管理</span>
					</el-menu-item>
					<el-menu-item index="8-8" @click="$router.push('/knowledgeClassroom/commentList')">
						<span class="icon-text">&nbsp;&nbsp;评论管理</span>
					</el-menu-item>
				</el-sub-menu>
			</el-menu>
		</div>
		<!-- 右边内容 -->			
		<div class="content-main">
			<!-- <div class="navTop horizontalView">
				<div class="nav_tools horizontalView">
					<SvgIcon :name="isCollapse?'expand':'fold'" class="icon-svg" @click="isCollapse=!isCollapse" />
				</div>
			</div> -->
			<!-- todo 内容组件 -->
			<router-view />
		</div>
	</div>
</template>

<script>
	export default {
		components: {
 
		},
		data: function() {
			return {
				isCollapse: false
			}
		},
		computed:{
			defaultActive () {
				// return window.location.hash.split('#')[1]
			}
		}
	}
</script>

<style>
	@import url('../assets/css/home.css');
	.logo{
		width: 158px;
		margin:20px;
	}
</style>